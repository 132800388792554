import React from 'react';

// router
import { Link, NavLink, withRouter } from 'react-router-dom';

// icons
import { ReactComponent as IconPlayArrow } from '../../../icons/material/play-arrow.svg';
import { ReactComponent as IconPause } from '../../../icons/material/pause.svg';

// styles
import './Button.scss';

function Button(props) {
	// vars
	let Tag = 'button';

	let btnAttributes = [];
	let btnClasses = '';
	let btnMods = '';
	let btnType = 'button';

	const renderIcon = () => {
		if(props.mods && props.mods.includes('play')) {

			return (
				<>
					<i className="btn__icon btn__icon--play">
						<IconPlayArrow />
					</i>
					<i className="btn__icon btn__icon--pause">
						<IconPause />
					</i>
				</>
			);
		} else if(props.icon) {
			return (
				<i className="btn__icon">
					{props.icon}
				</i>
			);
		}
		return false;
	}

	function click() {
		if(props.onClick) {
			props.onClick();
		}
	}

	if(props.classes) {
		for(let i = 0; i < props.classes.length; i++) {
			btnClasses += `${props.classes[i]} `;
		}
	}

	if(props.mods) {
		for(let i = 0; i < props.mods.length; i++) {
			btnMods += ` btn--${props.mods[i]}`;
		}
	}

	if(props.title) {
		btnAttributes['title'] = props.title;
	}

	if(props.disabled) {
		btnAttributes['disabled'] = props.disabled;
	}

	if(props.to) {
		Tag =  Link;

		if(props.isNav) {
			Tag =  NavLink;
			btnAttributes['activeClassName'] = 'btn--active';
			btnAttributes['exact'] = true;
		}

		btnAttributes['to'] = props.to;
	}

	if(props.href) {
		Tag = 'a';
		btnAttributes['href'] = props.href;
	}

	if(props.download) {
		btnAttributes['download'] = props.download;
	}

	if(props.icon) {
		btnMods += ' btn--icon';
	}

	if(props.refBtn) {
		btnAttributes['ref'] = props.refBtn;
	}

	btnAttributes['type'] = btnType;
	btnAttributes['className'] = `${btnClasses}btn${btnMods}`;
	btnAttributes['onClick'] = (e) => click(e);

  return (
		<Tag {...btnAttributes}>
			{renderIcon()}
			<span className="btn__text">
				{props.text}
			</span>
		</Tag>
  );
}

export default withRouter(Button);
