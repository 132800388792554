import React from 'react';

// router
import { useHistory } from 'react-router-dom';

// styles
import './GenresList.scss';

function GenresList(props) {

	const history = useHistory();

	function updateGenre(genre) {
		props.updateFilterGenre(genre);
		history.push('/');
	}

	const renderGenresList = () => {
		if(props.genres.length) {
			const genreListItems = props.genres.map((genre, key) => {
				return (
					<li className="list__item" key={key}>
						<button className="item__btn" onClick={(e) => updateGenre(genre)} type="button">
							<div className="item__image">
								<img alt="Cover" src={`/img/genres/${genre.name.replace(/ /g, '-').toLowerCase()}.jpg`} />
							</div>
							<h3 className="item__title">{genre.name}</h3>
						</button>
					</li>
				);
			});

			return (
				<ul className="genreslist__list">
					{genreListItems}
				</ul>
			);
		}
		return (
			<div>
				Sorry but we couldn't find any genres in your collection.
			</div>
		)
	}

  return (
    <section className="genreslist">
			<h2>Genres</h2>
			{renderGenresList()}
		</section>
  );
}

export default GenresList;
