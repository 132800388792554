import React from 'react';

// router
import { Link, withRouter } from 'react-router-dom';

// forms
import Input from '../../forms/Input/Input';
import InputFile from '../../forms/InputFile/InputFile';
import Select from '../../forms/Select/Select';

// ui
import Button from '../../ui/Button/Button';

// icons
import { ReactComponent as IconCalendarToday } from '../../../icons/material/calendar-today.svg';
import { ReactComponent as IconMood } from '../../../icons/material/mood.svg';
import { ReactComponent as IconSearch } from '../../../icons/material/search.svg';
import { ReactComponent as IconVideoLibrary } from '../../../icons/material/video-library.svg';

// styles
import './Header.scss';

function Header(props) {

	const modMedia = (props.media.length) ? ' header--media' : '';
	const today = new Date();
	const year = today.getFullYear();
	const yearOptions = [];

	for(let i = Number(year); i > 1970; i--) {
		yearOptions.push(i);
	}

	const renderNav = () => {
		const nav = [
			{
				icon: <IconVideoLibrary />,
				path: '/media',
				text: 'Media'
			},
			{
				icon: <IconMood />,
				path: '/genres',
				text: 'Genres'
			}
		];

		const navListItems = nav.map((item, key) => {
			return (
				<li key={key}>
					<Button icon={item.icon} isNav={true} mods={['transparent']} text={item.text} to={item.path} />
				</li>
			);
		});

		if(props.media.length) {
			return (
				<nav className="controls__nav">
					<ul className="nav__list">
						{navListItems}
					</ul>
				</nav>
			);
		}
	};

	const renderFilters = () => {
		if(props.media.length) {
			return (
				<div className="controls__filters">
					<h3 className="filters__title">Filters</h3>
					<ul className="filters__list">
						<li>
							<Input icon={<IconSearch />} onKeyUp={(e) => props.updateFilterSearch(e.target.value)} placeholder="Search" />
						</li>
						<li>
							<Select defaultOption="Year" icon={<IconCalendarToday />} onChange={(e) => props.updateFilterYear(e.target.value)} options={yearOptions} />
						</li>
					</ul>
				</div>
			);
		}
		return false;
	}

  return (
    <header className={`header${modMedia}`}>
			<div className="header__content">
				<div className="header__name">
					<h1 className="header__title">
						<Link to="/">
							my<span>Flix</span>
						</Link>
					</h1>
					<div className="header__tagline">Media Explorer</div>
				</div>
				<div className="header__controls">
					{renderNav()}
					{renderFilters()}
					<div className="controls__browse">
						<InputFile onChange={props.setFiles} multiple="multiple" placeholder="Select folder" refProp={props.refMediaFolder} webkitdirectory="true" />
					</div>
				</div>
			</div>
		</header>
  );
}

export default withRouter(Header);
