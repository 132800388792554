import React, { useEffect, useState } from 'react';

// forms
import Input from '../../forms/Input/Input';
import Select from '../../forms/Select/Select';

// ui
import Button from '../Button/Button';

// styles
import './MediaList.scss';

function MediaList(props) {
	const { sortMedia } = props;

	// state
	const [order, setOrder] = useState('asc');
	const [showInvalid, setShowInvalid] = useState(true);
	const [sort, setSort] = useState('title');

	function selectMedia(media) {
		props.setActiveMedia(media);

		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		});
	}

	const sortOptions = [
		{
			label: 'Title',
			value: 'title'
		},
		{
			label: 'Release Date',
			value: 'date'
		}
	];

	const orderOptions = [
		{
			label: 'ASC',
			value: 'asc'
		},
		{
			label: 'DESC',
			value: 'desc'
		}
	];

	let title = 'All Movies';

	const results = [];

	const renderBtns = () => {
		if(props.filterSearch || props.filterGenre || props.filterYear) {
			return (
				<li className="controls__li">
					<Button classes={['header__btn']} mods={['border', 'black', 'small']} onClick={(e) => props.clearFilters()} text="See All" />
				</li>
			);
		}
		return false;
	}

	const renderMediaList = () => {
		if(results.length) {
			const mediaListItems = results.map((media, key) => {
				const date = new Date(media.data.release_date);
				const year = date.getFullYear();
				const nameArray = media.file.name.split('.');
				const ext = nameArray[nameArray.length - 1];

				return (
					<li className="list__item" key={key} data-year={year} date-genres={media.data.genre_ids.join(',')} data-file-type={ext}>
						<button className="item__btn" onClick={(e) => selectMedia(media)}>
							<img alt="Poster" className="item__cover" src={`https://image.tmdb.org/t/p/w500${media.data.poster_path}`} />
							<h3 className="item__title">{media.data.title}</h3>
						</button>
					</li>
				);
			});

			// update active media
			if(!props.activeMedia) {
				props.setActiveMedia(results[0]);
			}

			return (
				<ul className="medialist__list">
					{mediaListItems}
				</ul>
			);
		}
		return (
			<p>Choose a local directory where your movies are located to browse your collection.</p>
		);
	}

	useEffect(() => {
		sortMedia(sort, order);
	}, [order, sort, sortMedia]);

	if(!props.media.length) {
		return false;
	}

	// filter media
	for(let i = 0; i < props.media.length; i++) {
		const date = new Date(props.media[i].data.release_date);
		const nameArray = props.media[i].file.name.split('.');
		const ext = nameArray[nameArray.length - 1];
		const year = date.getFullYear();

		const isYear = props.filterYear && year === Number(props.filterYear);
		const isGenre = props.filterGenre && props.media[i].data.genre_ids.includes(Number(props.filterGenre.id));
		const isSearch = props.filterSearch && props.media[i].data.title.toLowerCase().includes(props.filterSearch.toLowerCase());

		if(showInvalid || (!showInvalid && ext === 'mp4')) {
			if((!props.filterYear && !props.filterGenre && !props.filterSearch) || (isYear || isGenre || isSearch)) {
				results.push(props.media[i]);
			}
		}
	}

	if(props.filterGenre) {

		const genreObj = props.genres.find((genre) => {
			if(Number(genre.id) === Number(props.filterGenre.id)) {
				return genre;
			};
			return false;
		});

		title = `${genreObj.name}`;
	}

	if(props.filterSearch) {
		title = `Results for "${props.filterSearch}"`;
	}

	if(props.filterYear) {
		title = `Released in ${props.filterYear}`;
	}

  return (
		<section className="medialist">
			<header className="medialist__header">
				<div>
					<h2 className="header__title">
						{title}
					</h2>
					Showing <span>{results.length}</span> of <span>{results.length}</span> Results
				</div>
				<ul className="header__controls">
					<li className="controls__li">
						<label htmlFor="media-filter-sort" className="hidden">Sort</label>
						<Select id="media-filter-sort" mods={['pebble']} onChange={(e) => setSort(e.target.value)} options={sortOptions} value={sort} />
					</li>
					<li className="controls__li">
						<label htmlFor="media-filter-order" className="hidden">Order</label>
						<Select id="media-filter-order" mods={['pebble']} onChange={(e) => setOrder(e.target.value)} options={orderOptions} value={order} />
					</li>
					<li className="controls__li controls__li--invalid">
						<Input defaultChecked={showInvalid} id="medialist-show-invalid" onChange={(e) => setShowInvalid(e.target.checked)} type="checkbox" value={showInvalid} />
						<label htmlFor="medialist-show-invalid">Show Invalid Files</label>
					</li>
					{renderBtns()}
				</ul>
			</header>
			{renderMediaList()}
		</section>
  );
}

export default MediaList;
