import React from 'react';

// styles
import './Video.scss';

function Video(props) {
	// vars
	let videoAttributes = [];

	videoAttributes['controls'] = 'controls';
	videoAttributes['ref'] = props.refVideo;

	if(props.activeMedia) {
		videoAttributes['src'] = URL.createObjectURL(props.activeMedia.file);
		videoAttributes['poster'] = `https://image.tmdb.org/t/p/w500${props.activeMedia.data.backdrop_path}`;
	}

  return (
		<video {...videoAttributes}></video>
  );
}

export default Video;
