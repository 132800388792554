import React from 'react';

// styles
import './Select.scss';

function Select(props) {
	// vars
	let attributes = [];
	let classes = '';
	let mods = '';

	const renderIcon = () => {
		if(props.icon) {
			return (
				<i className="select__icon">
					{props.icon}
				</i>
			);
		}
		return false;
	}

	const renderOptions = () => {
		const options = [];

		if(props.defaultOption) {
			options.push(
				<option value="" key={0}>{props.defaultOption}</option>
			);
		}

		for(let i = 0; i < props.options.length; i++) {
			const optionAttributes = [];

			optionAttributes['key'] = i+1;
			optionAttributes['value'] = props.options[i].value;

			options.push(
				<option {...optionAttributes}>{props.options[i].label}</option>
			);
		}
		return options;
	}

	if(props.classes) {
		for(let i = 0; i < props.classes.length; i++) {
			classes += `${props.classes[i]} `;
		}
	}

	if(props.mods) {
		for(let i = 0; i < props.mods.length; i++) {
			mods += ` select--${props.mods[i]}`;
		}
	}

	if(props.icon) {
		mods += ' select--icon';
	}

	if(props.onChange) {
		attributes['onChange'] = props.onChange;
	}

	if(props.onClick) {
		attributes['onClick'] = props.onClick;
	}

	if(props.placeholder) {
		attributes['placeholder'] = props.placeholder;
	}

	if(props.readOnly) {
		attributes['readOnly'] = props.readOnly;
	}

	if(props.refProp) {
		attributes['ref'] = props.refProp;
	}

	if(props.value) {
		attributes['value'] = props.value;
	}

	attributes['className'] = `${classes}select${mods}`;

  return (
		<div className={`${classes}select${mods}`}>
			<select {...attributes}>
				{renderOptions()}
			</select>
			{renderIcon()}
		</div>
  );
}

export default Select;
