import React from 'react';

// styles
import './Input.scss';

function Input(props) {
	// vars
	let attributes = [];
	let classes = '';
	let mods = '';

	const renderIcon = () => {
		if(props.icon) {
			return (
				<i className="input__icon">
					{props.icon}
				</i>
			);
		}
		return false;
	}

	if(props.classes) {
		for(let i = 0; i < props.classes.length; i++) {
			classes += `${props.classes[i]} `;
		}
	}

	if(props.mods) {
		for(let i = 0; i < props.mods.length; i++) {
			mods += ` input--${props.mods[i]}`;
		}
	}

	if(props.icon) {
		mods += ' input--icon';
	}

	if(props.multiple) {
		attributes['multiple'] = props.multiple;
	}

	if(props.onChange) {
		attributes['onChange'] = props.onChange;
	}

	if(props.onClick) {
		attributes['onClick'] = props.onClick;
	}

	if(props.onKeyUp) {
		attributes['onKeyUp'] = props.onKeyUp;
	}

	if(props.placeholder) {
		attributes['placeholder'] = props.placeholder;
	}

	if(props.readOnly) {
		attributes['readOnly'] = props.readOnly;
	}

	if(props.refProp) {
		attributes['ref'] = props.refProp;
	}

	if(props.webkitdirectory) {
		attributes['webkitdirectory'] = props.webkitdirectory;
	}

	if(props.defaultChecked) {
		attributes['defaultChecked'] = props.defaultChecked;
	}

	attributes['type'] = (props.type) ? props.type : 'text';
	attributes['className'] = `${classes}input${mods}`;
	attributes['id'] = (props.id) ? props.id : null;
	attributes['name'] = (props.name) ? props.name : props.id;

  return (
		<div className={`${classes}input${mods}`}>
			<input {...attributes} />
			{renderIcon()}
		</div>
  );
}

export default Input;
