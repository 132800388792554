import React, { useEffect } from 'react';

// router
import { Redirect } from 'react-router-dom';

// ui
import GenresList from '../../ui/GenresList/GenresList';

// utils
import { formatPageTitle } from '../../../utils';

// styles
import './Genres.scss';

function Genres(props) {

	useEffect(() => {
		document.title = formatPageTitle('Genres');
	}, []);

	if(!props.media.length) {
		return <Redirect to="/" />;
	}

  return (
		<section className={`genres`}>
			<GenresList
				genres={props.genres}
				updateFilterGenre={props.updateFilterGenre}
			/>
		</section>
  );
}

export default Genres;
