import React from 'react';

// icons
import { ReactComponent as IconArrowDrawn } from '../../../icons/arrow-drawn.svg';

// styles
import './Tips.scss';

function Tips(props) {
  return (
		<section className={`tips section section--short section--bottom`}>
			<div className="wrap">
				<div className="tips__content">
					<header className="tips__header">
						<h3>Quick Tips</h3>
						<IconArrowDrawn />
					</header>
					<ul className="tips__list">
						<li className="list__item">
							<header className="item__header">
								<div className="item__num">1</div>
								<h3 className="item__title">MP4 Files Only</h3>
							</header>
							<p><strong>Movies have to be .mp4 files.</strong> If the file uses a different extension, you can choose to convert it.</p>
						</li>
						<li className="list__item">
							<header className="item__header">
								<div className="item__num">2</div>
								<h3 className="item__title">Sanitize File Names</h3>
							</header>
							<p><strong>Remove extraneous characters from the file names</strong> so our app can search for content effectively.</p>
						</li>
						<li className="list__item">
							<header className="item__header">
								<div className="item__num">3</div>
								<h3 className="item__title">No Sub Folders</h3>
							</header>
							<p><strong>Keep all of your files in a single folder.</strong> Movies in sub folders won't be detected.</p>
						</li>
					</ul>
				</div>
			</div>
		</section>
  );
}

export default Tips;
