import React, { useEffect } from 'react';

// router
import { Redirect } from 'react-router-dom';

// ui
import Button from '../../ui/Button/Button';
import Hero from '../../ui/Hero/Hero';
import Tips from '../../ui/Tips/Tips';

// utils
import { formatPageTitle } from '../../../utils';

// icons
import { ReactComponent as IconCheckCircle } from '../../../icons/material/check-circle.svg';

// styles
import './Home.scss';

function Home(props) {

	useEffect(() => {
		document.title = formatPageTitle('Home');
	}, []);

	function renderHeroIntro() {
		return (
			<>
				<h2>All of Your Movies in One Beautiful Catalog</h2>
				<p>Tired of the movies you see on all those streaming platforms? Use myFlix to explore the <strong>movies you already own</strong>. To get started, simply select a folder that has your movies in it.</p>
				<Button mods={['yellow']} onClick={(e) => props.updateMediaFolder()} text="Find Movies" />
			</>
		);
	}

	function renderHeroFeatures() {
		return (
			<>
				<h2>Learn More About Your Movie Collection</h2>
				<p><strong>Search</strong> by title or <strong>explore</strong> by year or genre. See all the meta data you'd want to know about the movies you love.</p>
				<ul className="hero__list">
					<li><IconCheckCircle /> Trailers</li>
					<li><IconCheckCircle /> Ratings</li>
					<li><IconCheckCircle /> Plot Synopsis</li>
					<li><IconCheckCircle /> Cast</li>
					<li><IconCheckCircle /> Crew</li>
				</ul>
			</>
		);
	}

	if(props.media.length) {
		return <Redirect to="/media" />;
	}

  return (
		<section className={`home`}>
			<Hero
				content={renderHeroIntro()}
				image="/img/hero/finder-browser.png"
			/>
			<Tips />
			<Hero
				content={renderHeroFeatures()}
				image="/img/hero/browser.png"
				mods={['alt']}
			/>
		</section>
  );
}

export default Home;
