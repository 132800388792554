import React from 'react';

// styles
import './Hero.scss';

function Hero(props) {
	// vars
	let classes = [];
	let mods = [];

	if(props.classes) {
		for(let i = 0; i < props.classes.length; i++) {
			classes += `${props.classes[i]} `;
		}
	}

	if(props.mods) {
		for(let i = 0; i < props.mods.length; i++) {
			mods += ` hero--${props.mods[i]}`;
		}
	}

  return (
		<section className={`${classes}hero${mods} section section--short`}>
			<div className="wrap">
				<div className="hero__content">
					{props.content}
				</div>
				<img className="hero__image" src={props.image} alt="Browser Window" />
			</div>
		</section>
  );
}

export default Hero;
