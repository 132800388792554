import React from 'react';

// router
import { Link, withRouter } from 'react-router-dom';

// styles
import './Footer.scss';

function Footer(props) {
	// vars
	let classHome = (props.location.pathname === '/') ? ' footer--home' : '';

  return (
    <footer className={`footer${classHome}`}>
			<div className="footer__content">
				<div className="footer__name">
					<h6 className="footer__title h4">
						<Link to="/">
							my<span>Flix</span>
						</Link>
					</h6>
				</div>
				<div className="footer__credit">
					<p>This product uses the <a href="https://www.themoviedb.org/documentation/api" target="_blank" rel="noopener noreferrer">TMDb API</a> but is not endorsed or certified by TMDb.</p>
				</div>
			</div>
		</footer>
  );
}

export default withRouter(Footer);
