// react
import React, { useEffect, useRef, useState } from 'react';

// router
import { BrowserRouter, Route, Switch } from 'react-router-dom';

// transitions
import { CSSTransition, TransitionGroup } from 'react-transition-group';

// layout
import Footer from './components/layout/Footer/Footer';
import Header from './components/layout/Header/Header';

// pages
import Genres from './components/pages/Genres/Genres';
import Home from './components/pages/Home/Home';
import Media from './components/pages/Media/Media';

// styles
import './App.scss';

function App(props) {
	// refs
	const refMediaFolder = useRef();

	// state
	const [activeMedia, setActiveMedia] = useState(null);
	const [activeMediaCredits, setActiveMediaCredits] = useState(null);
	const [activeMediaDetails, setActiveMediaDetails] = useState(null);
	const [files, setFiles] = useState([]);
	const [filterGenre, setFilterGenre] = useState('');
	const [filterSearch, setFilterSearch] = useState('');
	const [filterYear, setFilterYear] = useState('');
	const [genres, setGenres] = useState([]);
	const [media, setMedia] = useState([]);

	function updateMediaFolder() {
		if(refMediaFolder && refMediaFolder.current) {
			refMediaFolder.current.click();
		}
	}

	function clearFilters() {
		setActiveMedia(null);
		setFilterYear('');
		setFilterGenre('');
		setFilterSearch('');
	}

	function updateFilterGenre(genre) {
		setActiveMedia(null);
		setFilterYear('');
		setFilterGenre(genre);
	}

	function updateFilterYear(year) {
		setActiveMedia(null);
		setFilterGenre('');
		setFilterYear(year);
	}

	function updateFilterSearch(query) {
		setActiveMedia(null);
		setFilterGenre('');
		setFilterYear('');
		setFilterSearch(query);
	}

	function sortMedia(sort, order) {
		let mediaSorted = media;

		if(sort === 'title') {
			mediaSorted = media.sort(function(a, b) {
				return (order === 'desc') ? a.data.title.localeCompare(b.data.title) : b.data.title.localeCompare(a.data.title);
			});
		} else if(sort === 'date') {
			mediaSorted = media.sort(function(a, b) {
				return (order === 'desc') ? new Date(a.data.release_date) - new Date(b.data.release_date) : new Date(b.data.release_date) - new Date(a.data.release_date);
			});
		}

		console.log(`${sort} ${order}`, mediaSorted);

		setMedia(mediaSorted);
	}

	// genres
	useEffect(() => {
		const url = `https://api.themoviedb.org/3/genre/movie/list?api_key=${process.env.REACT_APP_MOVIE_DB_API_KEY}&language=en-US`;

		fetch(url, {mode: 'cors'})
			.then(function(response) {
				return response.json();
			})
			.then(function(data) {
				if(data.genres.length) {
					setGenres(data.genres);
				}
			})
			.catch(function(error) {
				console.log('Request Failed', error)
			});

	}, []);

	// credits
	useEffect(() => {
		if(activeMedia) {
			console.log(activeMedia);
			const url = `https://api.themoviedb.org/3/movie/${activeMedia.data.id}/credits?api_key=${process.env.REACT_APP_MOVIE_DB_API_KEY}`;
			console.log(url);

			fetch(url, {mode: 'cors'})
				.then(function(response) {
					return response.json();
				})
				.then(function(data) {
					if(data) {
						console.log(data);
						setActiveMediaCredits(data);
					}
				})
				.catch(function(error) {
					console.log('Request Failed', error)
				});
		}

	}, [activeMedia]);

	// details
	useEffect(() => {
		if(activeMedia) {
			const url = `https://api.themoviedb.org/3/movie/${activeMedia.data.id}?api_key=${process.env.REACT_APP_MOVIE_DB_API_KEY}&append_to_response=videos`;

			fetch(url, {mode: 'cors'})
				.then(function(response) {
					return response.json();
				})
				.then(function(data) {
					if(data) {
						setActiveMediaDetails(data);
						console.log('movie details', data);
					}
				})
				.catch(function(error) {
					console.log('Request Failed', error)
				});
		}

	}, [activeMedia]);

	// media
	useEffect(() => {
		if(files.length) {
			const promises = [];
			const fileTypes = ['avi', 'mp4', 'mov'];
			const filesWithData = [];

			for(let i = 0; i < files.length; i++) {
				const fileArray = files[i].name.split('.');
				const fileExt = fileArray.pop();
				const fileTitle = fileArray.join(' ').replace(/-/g, ' ');

				// if file has valid extension
				if(fileTypes.includes(fileExt)) {

					const url = `https://api.themoviedb.org/3/search/movie?api_key=${process.env.REACT_APP_MOVIE_DB_API_KEY}&language=en-US&query=${fileTitle}&page=1&include_adult=false`

					const fileFetch = fetch(url, {
						mode: 'cors'
					}).then(function(response) {
					    return response.json();
					  })
					  .then(function(data) {

							if(data.results.length) {
								const newItem = {
									file: files[i],
									data: data.results[0],
								};

								filesWithData.push(newItem);
							}
					  })
					  .catch(function(error) {
					    console.log('Request Failed', error);
					  });

					promises.push(fileFetch);
				}
			}

			// when all fetch requests are complete
			Promise.all(promises).then((responses) => {
				setMedia(filesWithData);
			});
		}
	}, [files]);

  return (
		<BrowserRouter>
			<Route render={({ location }) => (
				<div>
					<Header
						filterGenre={filterGenre}
						filterYear={filterYear}
						genres={genres}
						media={media}
						refMediaFolder={refMediaFolder}
						setFiles={setFiles}
						updateFilterGenre={updateFilterGenre}
						updateFilterSearch={updateFilterSearch}
						updateFilterYear={updateFilterYear}
					/>
					<main>
						<TransitionGroup>
							<CSSTransition classNames="fade" key={location.key} timeout={300}>
								<Switch>
									<Route exact path="/">
										<Home media={media} updateMediaFolder={updateMediaFolder} />
									</Route>
									<Route exact path="/media">
										<Media
											activeMedia={activeMedia}
											activeMediaCredits={activeMediaCredits}
											activeMediaDetails={activeMediaDetails}
											clearFilters={clearFilters}
											filterGenre={filterGenre}
											filterSearch={filterSearch}
											filterYear={filterYear}
											genres={genres}
											media={media}
											refMediaFolder={refMediaFolder}
											setActiveMedia={setActiveMedia}
											sortMedia={sortMedia}
											updateFilterGenre={updateFilterGenre}
											updateMediaFolder={updateMediaFolder}
										/>
									</Route>
									<Route exact path="/genres">
										<Genres
											genres={genres}
											media={media}
											updateFilterGenre={updateFilterGenre}
										/>
									</Route>
								</Switch>
							</CSSTransition>
						</TransitionGroup>
					</main>
					<Footer />
				</div>
			)}/>
		</BrowserRouter>
  );
}

export default App;
