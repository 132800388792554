import React, { useEffect, useRef, useState } from 'react';

// router
import { Redirect } from 'react-router-dom';

// ui
import MediaList from '../../ui/MediaList/MediaList';
import Player from '../../ui/Player/Player';

// utils
import { downloadBlob, formatPageTitle } from '../../../utils';

// styles
import './Media.scss';

function Media(props) {
	// refs
	const refVideo = useRef();
	const refPercentage = useRef();
	const refProgress = useRef();

	// state
	const [mediaConverting, setMediaConverting] = useState([]);
	const [mp4BlobUrl, setMp4BlobUrl] = useState(null);

	// vars
	let convertingProgress = 0;

	// ffmpeg
	const { createWorker } = window.FFmpeg;
	const worker = createWorker({
		logger: ({ message }) => {
			console.log('message', message)
		},
		progress: (p) => {
			console.log('progress', p.ratio);
			console.log(refPercentage.current);
			const percentage = (p.ratio * 100);

			if(refPercentage.current) {
				refPercentage.current.innerText = Math.round(percentage);
			}

			if(refProgress.current) {
				refProgress.current.value = percentage;
			}
		}
	});

	const convertVideo = async (file) => {

		if(mediaConverting.length) {
			alert('Sorry but only one video can be converted at at a time.');
			return false;
		}

		// split file name
		const fileNameArray = file.name.split('.');

		// remove extension from file name
		fileNameArray.pop();

		// define new file name
		const fileNameNew = `${fileNameArray.join('.')}.mp4`;

		// indicate that converting has started
		setMediaConverting((mediaConverting) => [...mediaConverting, file.name]);

		// start converting
		await worker.load();
		await worker.write(file.name, file);
		await worker.transcode(file.name, fileNameNew);
		console.log(`Transcoding ${file.name} into ${fileNameNew}`);

		// read file data
		const { data } = await worker.read(fileNameNew);

		// create blob from data
		const blob = new Blob([data.buffer], {
			type: 'video/mp4'
		});

		const blobURL = URL.createObjectURL(blob);

		console.log('blob url', blobURL);

		// set video source to new file as url
		refVideo.current.src = blobURL;

		// trigger download of new file
		downloadBlob(blob, fileNameNew);

		// set mp4 blob url
		setMp4BlobUrl({
			file: fileNameNew,
			blob: blobURL
		});

		return blob;
  }

	// page title
	useEffect(() => {
		document.title = formatPageTitle('Media');
	}, []);

	if(!props.media.length) {
		return <Redirect to="/" />;
	}

  return (
		<section className={`media`}>
			<Player
				activeMedia={props.activeMedia}
				activeMediaCredits={props.activeMediaCredits}
				activeMediaDetails={props.activeMediaDetails}
				convertingProgress={convertingProgress}
				convertVideo={convertVideo}
				filterGenre={props.filterGenre}
				filterSearch={props.filterSearch}
				filterYear={props.filterYear}
				genres={props.genres}
				mediaConverting={mediaConverting}
				mp4BlobUrl={mp4BlobUrl}
				refMediaFolder={props.refMediaFolder}
				refPercentage={refPercentage}
				refProgress={refProgress}
				refVideo={refVideo}
				updateFilterGenre={props.updateFilterGenre}
				updateMediaFolder={props.updateMediaFolder}
			/>
			<MediaList
				activeMedia={props.activeMedia}
				clearFilters={props.clearFilters}
				genres={props.genres}
				filterGenre={props.filterGenre}
				filterSearch={props.filterSearch}
				filterYear={props.filterYear}
				media={props.media}
				setActiveMedia={props.setActiveMedia}
				sortMedia={props.sortMedia}
			/>
		</section>
  );
}

export default Media;
