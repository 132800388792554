import React, { useRef } from 'react';

// forms
import Input from '../../forms/Input/Input';

// icons
import { ReactComponent as IconFolderOpen } from '../../../icons/material/folder-open.svg';

// styles
import './InputFile.scss';

function InputFile(props) {
	// refs
	const refText = useRef();

	// vars
	const inputFileProps = [];
	const inputTextProps = [];

	function updateInput(e) {
		const pathArray = e.target.files[0].webkitRelativePath.split('/');

		refText.current.value = `${pathArray[0]}/`;

		if(props.onChange) {
			props.onChange(e.target.files);
		}
	}

	// default file attributes
	inputFileProps['classes'] = ['input__file'];
	inputFileProps['onChange'] = (e) => updateInput(e);
	inputFileProps['refProp'] = props.refProp;
	inputFileProps['type'] = 'file';

	if(props.multiple) {
		inputFileProps['multiple'] = props.multiple;
	}

	if(props.webkitdirectory) {
		inputFileProps['webkitdirectory'] = props.webkitdirectory;
	}

	// default text attributes
	inputTextProps['classes'] = ['input__text'];
	inputTextProps['icon'] = <IconFolderOpen />;
	inputTextProps['onClick'] = (e) => props.refProp.current.click();
	inputTextProps['readOnly'] = 'readOnly';
	inputTextProps['refProp'] = refText;
	inputTextProps['type'] = 'text';

	if(props.placeholder) {
		inputTextProps['placeholder'] = props.placeholder;
	}

  return (
    <div className="input input--file">
			<Input {...inputFileProps} />
			<Input {...inputTextProps} />
			<button className="input__btn" onClick={(e) => props.refProp.current.click()}>Browse</button>
		</div>
  );
}

export default InputFile;
