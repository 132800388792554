import React, { useEffect, useRef, useState } from 'react';

// ui
import Button from '../Button/Button';
import Video from '../Video/Video';

// icons
import { ReactComponent as IconCalculate } from '../../../icons/material/calculate.svg';
import { ReactComponent as IconCalendarToday } from '../../../icons/material/calendar-today.svg';
import { ReactComponent as IconClear } from '../../../icons/material/clear.svg';
import { ReactComponent as IconDuo } from '../../../icons/material/duo.svg';
import { ReactComponent as IconMonetization } from '../../../icons/material/monetization.svg';
import { ReactComponent as IconMovie } from '../../../icons/material/movie.svg';
import { ReactComponent as IconPeople } from '../../../icons/material/people.svg';
import { ReactComponent as IconSave } from '../../../icons/material/save.svg';
import { ReactComponent as IconStars } from '../../../icons/material/stars.svg';
import { ReactComponent as IconTrendingUp } from '../../../icons/material/trending-up.svg';

// utils
import { addCommasToNumber } from '../../../utils';

// styles
import './Player.scss';

function Player(props) {
	// props
	const { refVideo } = props;

	// refs
	const refBtnPlay = useRef();
	const refTrailer = useRef();

	// state
	const [isCastActive, setIsCastActive] = useState(false);
	const [isCrewActive, setIsCrewActive] = useState(false);
	const [youtubePlayer, setYoutubePlayer] = useState(null);

	// vars
	const ext = (props.activeMedia) ? props.activeMedia.file.name.split('.').pop() : null;
	const isValidFormat = (ext === 'mp4');
	const classActive = (props.activeMedia) ? ' player--active' : '';

	function playVideo() {
		if(refVideo && refVideo.current) {
			if(refVideo.current.paused) {
				refVideo.current.requestFullscreen();
				refVideo.current.play();
			} else {
				refVideo.current.pause();
			}
		}
		return false;
	}

	function playTrailer() {
		// pause video
		if(refVideo.current) {
			refVideo.current.pause();
		}

		// display trailer iframe
		refTrailer.current.classList.add('player__iframe--active');
		refTrailer.current.requestFullscreen();

		// play trailer
		if(youtubePlayer) {
			youtubePlayer.playVideo();
		}
	}

	function closeCredits() {
		setIsCastActive(false);
		setIsCrewActive(false);
	}

	const renderMovieDetails = () => {

		if(props.activeMedia && props.activeMediaDetails) {
			// vars
			const date = new Date(props.activeMedia.data.release_date);
			const year = date.getFullYear();

			const renderCredits = (o) => {
				if(props.activeMediaCredits) {
					const creditsClassActive = o.active ? ' player__credits--active' : '';

					const creditItems = props.activeMediaCredits[o.type].map((credit, key) => {
						const img = credit.profile_path ? `https://image.tmdb.org/t/p/w500${credit.profile_path}` : '/img/avatar.png';
						const subtitle = credit.character ? credit.character : credit.job;
						return (
							<li className="list__item" key={key}>
								<img src={img} alt={`${credit.name} Avatar`} />
								<div>
									<h3 className="item__title item__title--bold h4">
										{credit.name}
									</h3>
									<h4 className="item__title">{subtitle}</h4>
								</div>
							</li>
						);
					});

					return (
						<div className={`player__credits player__credits--${o.type}${creditsClassActive}`}>
							<div className="credits__mask">
								<h3>{o.title}</h3>
								<ul className="credits__list">
									{creditItems}
								</ul>
								<Button classes={['credits__close']} icon={<IconClear />} onClick={(e) => closeCredits()} mods={['circle']} title={`Close ${o.title}`} />
							</div>
						</div>

					);
				}
				return <p>{`The ${o.type} could not be found for this movie.`}</p>;
			}

			const renderGenres = () => {
				if(props.genres) {

					const genreStrings = [];

					for(let i = 0; i < props.activeMedia.data.genre_ids.length; i++) {
						const genreFound = props.genres.find((genre) => {
							return genre.id === props.activeMedia.data.genre_ids[i];
						});

						if(genreFound) {
							genreStrings.push(genreFound);
						}
					}

					const genreListItems = genreStrings.map((genre, key) => {
						return (
							<li key={key}>
								<Button mods={['chip']} onClick={(e) => props.updateFilterGenre(genre)} text={genre.name} />
							</li>
						);
					});

					return (
						<ul className="player__genres">
							{genreListItems}
						</ul>
					)
				}
				return false;
			};

			const renderConvertBtn = () => {
				if(!isValidFormat) {
					return <Button mods={['border', 'small', 'white']} onClick={(e) => props.convertVideo(props.activeMedia.file)} text="Convert" />;
				}
				return false;
			}

			const renderVideoBtns = () => {
				if(props.activeMedia) {
					return (
						<>
							<Button classes={['btns__btn']} disabled={(!isValidFormat) ? 'disabled' : ''} mods={['play']} onClick={(e) => playVideo()} refBtn={refBtnPlay} title="Play" />
						</>
					)
				}
				return false;
			}

			const renderConvertProgress = () => {
				if(props.mediaConverting.includes(props.activeMedia.file.name)) {
					return (
						<div className="player__progress">
							<div className="progress__form">
								<label htmlFor="progress__progress" className="hidden">Progress</label>
								<progress className="progress__progress" id="progress__progress" max="100" ref={props.refProgress} value="0"></progress>
							</div>
							<div className="progress__val">
								<span ref={props.refPercentage}>0</span>%
							</div>
						</div>
					);
				}
				return false;
			}

			const renderBtnTrailer = () => {
				if(props.activeMediaDetails) {
					return (
						<li>
							<Button classes={['tooltip']} icon={<IconMovie />} mods={['circle', 'border', 'big']} onClick={(e) => playTrailer(e)} title="Watch Trailer" />
						</li>
					);
				}
				return false;
			}

			const renderBtnDownloadMp4 = () => {
				if(props.mp4BlobUrl) {
					return <Button classes={['player__download']} download={props.mp4BlobUrl.file} href={props.mp4BlobUrl.blob} text="Download MP4" />;
				}
			}

			return(
				<>
				<img alt="Background" className="player__bg" src={`https://image.tmdb.org/t/p/w500${props.activeMedia.data.poster_path}`} loading="lazy" />
				<div className="player__copy">
					<div className="player__btns">
						{renderVideoBtns()}
					</div>
					<h2 className="player__title">{props.activeMedia.data.title}</h2>
					<ul className="player__meta">
						<li className="meta__item tooltip" title="Year Released">
							<IconCalendarToday />
							<span>{year}</span>
						</li>
						<li className="meta__item tooltip" title="Popularity">
							<IconTrendingUp />
							<span>{props.activeMedia.data.popularity.toFixed(1)}</span>
						</li>
						<li className="meta__item tooltip" title={`Avg. Rating of ${addCommasToNumber(props.activeMedia.data.vote_count)} Voters`}>
							<IconStars />
							<span>{props.activeMedia.data.vote_average.toFixed(1)}</span>
						</li>
						<li className="meta__item tooltip" title="File Size">
							<IconSave />
							<span>{Math.round(props.activeMedia.file.size / 1000000)} MB</span>
						</li>
						<li className="meta__item tooltip" title="Budget">
							<IconCalculate />
							<span>${Math.round(props.activeMediaDetails.budget / 1000000)}M</span>
						</li>
						<li className="meta__item tooltip" title="Revenue">
							<IconMonetization />
							<span>${Math.round(props.activeMediaDetails.revenue / 1000000)}M</span>
						</li>
					</ul>
					{renderGenres()}
					<div className="player__plot">
						{props.activeMedia.data.overview}
					</div>
					<div className="player__controls">
						<ul className="controls__list">
							{renderBtnTrailer()}
							<li>
								<Button classes={['tooltip']} icon={<IconPeople />} mods={['circle', 'border', 'big']} onClick={(e) => setIsCastActive(true)} title="See Cast" />
							</li>
							<li>
								<Button classes={['tooltip']} icon={<IconDuo />} mods={['circle', 'border', 'big']} onClick={(e) => setIsCrewActive(true)} title="See Crew" />
							</li>
						</ul>
						{renderConvertBtn()}
					</div>
					{renderConvertProgress()}
					{renderBtnDownloadMp4()}
				</div>
				{renderCredits({
					active: isCastActive,
					title: 'Cast',
					type: 'cast'
				})}
				{renderCredits({
					active: isCrewActive,
					title: 'Crew',
					type: 'crew'
				})}
				</>
			);
		} else {
			if(props.filterSearch || props.filterYear || props.filterGenre) {

				let phrase = `for`;
				let term = props.filterSearch;

				if(props.filterYear) {
					phrase = `released in the year`;
					term = props.filterYear;
				} else if(props.filterGenre) {
					phrase = ` in`;
					term = props.filterGenre.name;
				}
				return (
					<div className="player__empty">
						<h2 className="about__title">
							Not Found
						</h2>
						<div className="empty__copy">
							We couldn't find any results {phrase} <strong>{term}</strong>.
						</div>
					</div>
				);
			} else {
				return (
					<div className="player__about">
						<h2 className="about__title">Get Started</h2>
						<div>
							<div className="about__intro">
								myFlix is an app that allows you to browse your own personal movie collection.
							</div>
							<div className="about__btns">
								<Button onClick={(e) => props.updateMediaFolder()} text="Explore" />
							</div>
						</div>
					</div>
				);
			}
		}
	}

	const renderTrailer = () => {
		if(props.activeMediaDetails && props.activeMediaDetails.videos.results.length) {
			for(let v = 0; v < props.activeMediaDetails.videos.results.length; v++) {
				if(props.activeMediaDetails.videos.results[v].site === 'YouTube') {
					return <iframe className={`player__iframe`} id="trailer" ref={refTrailer} src={`https://www.youtube.com/embed/${props.activeMediaDetails.videos.results[v].key}?enablejsapi=1`} title="Trailer" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>;
				}
			}
			return false;
		}
		return false;
	}

	// video controls
	useEffect(() => {
		if(refVideo.current) {
			// pause
			refVideo.current.onpause = () => {
				console.log('paused');
				refBtnPlay.current.classList.remove('btn--active');
			}

			// play
			refVideo.current.onplay = () => {
				console.log('playing');
				refBtnPlay.current.classList.add('btn--active');
				refTrailer.current.classList.remove('player__iframe--active');
			}
		}
	}, [refVideo]);

	// when yt iframe api is ready
	window.onYouTubeIframeAPIReady = () => {

		function onPlayerReady(e) {
			setYoutubePlayer(e.target);
		}

		// create new yt player
		new window.YT.Player('trailer', {
			events: {
				'onReady': onPlayerReady
			}
		});
	}

	// youtube
	useEffect(() => {
		// vars
		const tag = document.createElement('script');
		const firstScriptTag = document.getElementsByTagName('script')[0];

		// append iframe api script
    tag.src = "https://www.youtube.com/iframe_api";
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
	}, []);

	// hide trailer on each load
	useEffect(() => {
		if(refTrailer.current) {
			refTrailer.current.classList.remove('player__iframe--active');
		}
	});

  return (
		<div className={`player${classActive}`}>
			<div className="player__grid">
				<div className="player__video">
					<Video activeMedia={props.activeMedia} refVideo={refVideo} />
					{renderTrailer()}
				</div>
				<div className="player__details">
					{renderMovieDetails()}
				</div>
			</div>
		</div>
  );
}

export default Player;
